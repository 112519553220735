import Swal from "sweetalert2";
// Indica si estamos en modo sandbox (desarrollo) o producción
const SANDBOX = false;

// Configuración basada en el modo (sandbox o producción)
const secure = !SANDBOX;

// Define el protocolo HTTP/HTTPS basado en la variable `secure`
const protocol = `http${secure ? 's' : ''}`;

// Define el protocolo WebSocket basado en la variable `secure`
const wsProtocol = `ws${secure ? 's' : ''}`;

// Host donde se encuentra el servidor
const HOST = SANDBOX ? `192.168.0.17:8300` : `authapi.intelisysdev.com`;

// Construir las URLs base para HTTP y WebSocket
const BASE_HOST = `${protocol}://${HOST}/api`;
const WS_HOST = `${wsProtocol}://${HOST}/`;

// URLs de los distintos servicios
const API_BASE = `${BASE_HOST}/authentication/`;
const API_CORE = `${BASE_HOST}/test/`;
export const WS_EMBALAJE = `${WS_HOST}ws/embalaje/?token=<token>`;
export const WS_SSH = `${WS_HOST}ssh/?token=<token>`;

// Cabeceras comunes para todas las solicitudes
const base_header = { 'Content-Type': 'application/json' };


async function handleApiResponse(response) {
    if (response.status === 401) {
        await Swal.fire({
            title: 'Sesión expirada',
            text: 'Tu sesión ha expirado. Por favor, sal e  inicia sesión nuevamente.',
            icon: 'warning',
            confirmButtonText: 'Aceptar'
        });
        return null;
    }

    const data = await response.json();
    if (response.ok || response.status === 204) {
        return { status: true, data };
    } else {
        return { status: false, mensaje: data.message || response.statusText, data };
    }
}

export function loginF(data) {
    const url = `${API_BASE}login/login/`;

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then(response => {
        // Debemos capturar el estado de 'response' antes de convertirlo a JSON
        const isResponseOk = response.ok; // Capturamos si la respuesta fue exitosa
        const status = response.status; // Capturamos el estado HTTP
        const statusText = response.statusText; // Capturamos el texto del estado
        return response.json().then(data => {
            if (isResponseOk) {
                return {
                    mensaje: "Login exitoso",
                    data: data
                };
            } else {
                return {
                    mensaje: `Error ${status}: ${data.message || statusText}`,
                    data: data
                };
            }
        });
    })
    .catch(error => {
        if (error.name === "TypeError" && error.message === "Failed to fetch") {
            return {
                mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
                data: null
            };
        } else {
            return {
                mensaje: "Error desconocido durante el login",
                data: null
            };
        }
    });
}





export function refresh(refreshToken) {
    const url = `${API_BASE}login/refresh/`;
    const data = {
        refresh: refreshToken
    };

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
          console.log('Token refreshed:', data);
          return data;
      })
      .catch(error => {
          console.error('Error refreshing token:', error);
      });
}


export async function obtenerPermisosUsuario(token) {
    const url = `${API_BASE}login/generic_user_permissions/`; // Asegúrate de que la URL es correcta

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`  // Autorización con token
        }
    })
    .then(response => {
        if (response.ok) {
            return response.json().then(data => ({
                mensaje: "Permisos obtenidos exitosamente",
                data: data
            }));
        } else {
            return response.json().then(data => {
                return { mensaje: `Error ${response.status}: ${response.statusText}`, data: data };
            });
        }
    })
    .catch(error => {
        return {
            mensaje: "Error al obtener permisos: " + error.message,
            data: null
        };
    });
}





// Función para solicitar la generación del OTP
export function solicitarOtp(token) {
    const url = `${API_BASE}otp/generar_otp/`;
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Asegúrate de enviar el token de autorización
        },
    })
    .then(response => response.json())
    .then(data => {
        console.log('OTP generado:', data);
        return data;
    })
    .catch(error => {
        console.error('Error generando OTP:', error);
    });
}


// Función para validar el OTP
export function validarOtp(codigo, token) {
    const url = `${API_BASE}otp/validar_otp_configurado/`;
    const data = {
        codigo: codigo
    };

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Asegúrate de enviar el token de autorización si es necesario
        },
        body: JSON.stringify(data)
    })
        .then(response => response.json())
        .then(data => {
            console.log('OTP validado:', data);
            return data;
        })
        .catch(error => {
            console.error('Error validando OTP:', error);
        });
}






















export async function listarUsuarios(token) {
    const url = `${API_CORE}admin_panel/listar_usuarios/`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`
            }
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching usuarios:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function agregarUsuario(token, userData) {
    const url = `${API_CORE}admin_panel/agregar_usuario/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userData)
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error agregando el usuario:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}


export async function cambiarEstadoActivoDeUsuario(token, userId) {
    const url = `${API_CORE}admin_panel/cambiar_estado_activo_de_usuario/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: userId })
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error cambiando el estado activo del usuario:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}


export async function eliminarUsuario(token, usuarioId) {
    const url = `${API_CORE}admin_panel/eliminar_usuario/`;

    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: usuarioId })
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error eliminando el usuario:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}

export async function agregarUsuarioGrupo(token, userId, groupId) {
    const url = `${API_CORE}admin_panel/agregar_usuario_grupo/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_id: userId, group_id: groupId })
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error agregando grupo al usuario:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}


export async function quitarUsuarioGrupo(token, userId, groupId) {
    const url = `${API_CORE}admin_panel/quitar_usuario_grupo/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                ...base_header,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_id: userId, group_id: groupId })
        });

        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error quitando grupo del usuario:', error);
        return {
            status: false,
            mensaje: "No se pudo conectar con el servidor, verifique su conexión a la red",
            data: null
        };
    }
}
