import logo from './logo.svg';
import './App.css';
import {AuthProvider, useAuth} from "./Providers/AuthProvider";
import SignIn from "./Vistas/Login/login";
import Sidebar from "./Globales/Sidebar";
import React from 'react';
import SshTerminal from "./Vistas/SSHManager/Manager";
import {SeleccionEmpresa} from "./Vistas/SeleccionEmpresa/SeleccionEmpresa";
import Configuracion from "./Vistas/Configuracion/Configuracion";
function AuthenticatedApp() {
  const { authTokens,actualView,setView,empresaSeleccionada } = useAuth();
  return (
      <>
          {console.log("Se ve que la empresa seleccioonada es: ",empresaSeleccionada)}
      {empresaSeleccionada ? (
          <>

    <Sidebar/>


    {/* Componentes protegidos post-login */}
    {actualView === 'configuracion' && (<Configuracion/>)}
    {actualView === 'ssh_terminal' && (<SshTerminal token={authTokens.accessToken} />)}

    </>
        ) : (<SeleccionEmpresa/>)}
      </>

  );
}


function App() {
    const { authTokens,actualView,setView } = useAuth();
  return (
    <div className="App">
          {actualView === 'login' ? <SignIn /> : <AuthenticatedApp />}
    </div>
  );
}

export default App;
