import React, {useEffect, useState} from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import InventoryIcon from '@mui/icons-material/Inventory';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Divider, useTheme } from '@mui/material';
import { useAuth } from "../Providers/AuthProvider";
import PeopleIcon from '@mui/icons-material/People';
import TerminalIcon from '@mui/icons-material/Terminal';
function Sidebar() {
  const [open, setOpen] = useState(false);
  const { setView, logout, permisos, superuser,username } = useAuth();
  const theme = useTheme();

  const handleClick = () => {
    setOpen(!open);
  };
  const changeView = (view) => {
    setView(view);
  };

  const hasPermission = (permission) => {
    return superuser || (permisos && permisos.includes(permission));
  };

  useEffect(() => {
    console.log("permisos en el SIdebar: ",permisos )
  }, [permisos]);

  return (
    <Drawer variant="permanent" anchor="left">
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            <ListItemButton onClick={handleClick}>
              <ListItemIcon>
                <Avatar alt={username} src="/static/images/avatar/1.jpg" style={{ backgroundColor: theme.palette.primary.dark }} />
              </ListItemIcon>
              <ListItemText primary={username} />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListSubheader>
        }
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4, pb: 2, pt: 2 }} onClick={() => changeView('configuracion')}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Configuración" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={logout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Cerrar Sesión" />
            </ListItemButton>
          </List>
        </Collapse>
        <Divider sx={{ borderBottomWidth: 5, borderColor: 'rgba(200, 200, 200, 0.5)' }} />

        {(hasPermission('puede_agregar_usuarios')) && (
          <ListItemButton sx={{ pb: 2, pt: 2 }} onClick={() => changeView('administrar_usuarios')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Usuarios" />
          </ListItemButton>
        )}

        {(hasPermission('puede_conectarse_via_ssh')) && (
          <ListItemButton sx={{ pb: 2 }} onClick={() => changeView('ssh_terminal')}>
            <ListItemIcon>
              <TerminalIcon />
            </ListItemIcon>
            <ListItemText primary="SSH" />
          </ListItemButton>
        )}
      </List>
    </Drawer>
  );
}

export default Sidebar;
