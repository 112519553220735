import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import {jwtDecode} from 'jwt-decode';
import Swal from 'sweetalert2';
import { loginF, obtenerPermisosUsuario, refresh as refreshTokens } from '../Globales/MetodosAPIs';

const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [authTokens, setAuthTokens] = useState(() => {
    const token = localStorage.getItem('authTokens');
    return token ? JSON.parse(token) : { accessToken: null, refreshToken: null };
  });

  const [superuser, setSuperuser] = useState(false);

  const [empresasDisponibles, setEmpresas] = useState(() => {
    const storedEmpresas = localStorage.getItem('empresasDisponibles');
    return storedEmpresas ? JSON.parse(storedEmpresas) : [];
  });

  const [empresaSeleccionada, setEmpresaSeleccionada] = useState(() => {
    const storedEmpresa = localStorage.getItem('empresaSeleccionada');
    return storedEmpresa ? JSON.parse(storedEmpresa) : null;
  });
  const [showVerification, setShowVerification] = useState({ show: false, type: 'email' });
  const [userInformation, setUserInformation] = useState(() => {
    const storedUserInfo = localStorage.getItem('userInformation');
    return storedUserInfo ? JSON.parse(storedUserInfo) : { nombre: 'Desconocido', otp_activo: false };
  });
  const [permisos, setPermisos] = useState(() => {
    const storedPermisos = localStorage.getItem('permisos');
    try {
      return storedPermisos ? JSON.parse(storedPermisos) : [];
    } catch (error) {
      console.error('Error parsing stored permisos:', error);
      return [];
    }
  });

  const [actualView, setView] = useState('login');
  const [loginError, setLoginError] = useState(null);
  const expirationTime = useRef(null); // Usar useRef para expirationTime


  const seleccionar_empresa = (empresa) =>{
    localStorage.setItem('empresaSeleccionada', JSON.stringify(empresa));
    setEmpresaSeleccionada(empresa);
  }

  useEffect(() => {
    const loadAuthData = async () => {
      const storedTokens = localStorage.getItem('authTokens');
      if (storedTokens) {
        const parsedTokens = JSON.parse(storedTokens);
        setAuthTokens(parsedTokens);
        const decodedToken = jwtDecode(parsedTokens.accessToken);
        expirationTime.current = decodedToken.exp; // Asignar a expirationTime.current
        setSuperuser(decodedToken.super);
        const userInfo = {
          nombre: capitalizeUsername(decodedToken.user_data.nombre),
          otp_activo: decodedToken.user_data.otp_activo,
        };
        setUserInformation(userInfo);
        const empresas_disponibles = decodedToken.user_data.empresas
        setEmpresas(empresas_disponibles);
        localStorage.setItem('empresasDisponibles', JSON.stringify(empresas_disponibles));
        handleEmpresaSeleccionada(decodedToken.user_data.empresas);
        handlePermisos(parsedTokens.accessToken, decodedToken.user_data.empresas);
      } else {
        setView('login');
      }
    };

    loadAuthData();
  }, []);

  const capitalizeUsername = (name) => {
    if (typeof name !== 'string' || name.length === 0) {
        return '';
    }
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};


  useEffect(() => {
    if (authTokens.accessToken) {
      localStorage.setItem('authTokens', JSON.stringify(authTokens));
      const decodedToken = jwtDecode(authTokens.accessToken);
      expirationTime.current = decodedToken.exp; // Asignar a expirationTime.current
      setSuperuser(decodedToken.super);
      const userInfo = {
        nombre: capitalizeUsername(decodedToken.user_data.nombre),
        otp_activo: decodedToken.user_data.otp_activo,
      };
      setUserInformation(userInfo);
      setEmpresas(decodedToken.user_data.empresas);
      handleEmpresaSeleccionada(decodedToken.user_data.empresas);
      handlePermisos(authTokens.accessToken, decodedToken.user_data.empresas);
    }
  }, [authTokens]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (expirationTime.current) {
        const currentTime = Date.now() / 1000;

        const timeLeft = expirationTime.current - currentTime;
        if (timeLeft < 2) {
          refresh();
        }
      }
    }, 1000);

    return () => clearInterval(intervalId); // Limpiar el intervalo al desmontar
  }, []);

  const handlePermisos = async (accessToken, empresas) => {
    try {
      const permisosResponse = await obtenerPermisosUsuario(accessToken);
      const permisosData = permisosResponse ? permisosResponse.data : {};
      const permisos = permisosData.permissions || [];
      console.log("Permisos Recibidos: ",permisos);
      setPermisos(permisos);
      localStorage.setItem('permisos', JSON.stringify(permisos));

      if (permisos.length === 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Login exitoso',
          text: 'No tienes permisos para usar la aplicación actual',
        });
        setView('login');
      } else {
        const tienePermisoVerCuentas = permisos.includes('puede_ver_cuentas');
        if (!empresaSeleccionada) {
          if (empresas.length > 1) {
            setView('seleccion_empresa');
          } else if (empresas.length === 1) {
            setEmpresaSeleccionada(empresas[0].id);
            localStorage.setItem('empresaSeleccionada', JSON.stringify(empresas[0].id));
          }
        }
        if (tienePermisoVerCuentas) {
          setView('dashboard');
        } else {
          setView('embalaje');
        }
      }
    } catch (error) {
      console.error('Error fetching permissions:', error);
      setLoginError('Error al obtener los permisos');
    }
  };

  const handleEmpresaSeleccionada = (empresas) => {
    if (empresas.length === 1) {
      setEmpresaSeleccionada(empresas[0].id);
      localStorage.setItem('empresaSeleccionada', JSON.stringify(empresas[0].id));
    } else if (empresas.length > 1) {
      console.log("CAMBIAMOS A VISTA DEE SELECCION DE EMPRESA_")
      setView('seleccion_empresa');
    }
  };

  const login = async (username, password, huella_digital, verification_code, type) => {
    try {
      const data = {
        username,
        password,
        huella_digital
      };

      if (type === 'email') {
        data.verification_code = verification_code;
      } else if (type === 'otp') {
        data.otp_code = verification_code;
      }

      const response = await loginF(data);
      if (response.data && response.data.access_token && response.data.refresh) {
        handleSuccessfulLogin(response);
      } else if (response.data && response.data.status === 'awaiting_verification') {
        setShowVerification({ show: true, type: 'email' });
        setLoginError(response.data.message);
      } else if (response.data && response.data.status === 'awaiting_otp') {
        setShowVerification({ show: true, type: 'otp' });
        setLoginError(response.data.message);
      } else {
        setLoginError(response.data.message || 'Error desconocido');
      }
    } catch (err) {
      handleLoginError(err);
    }
  };

  async function handleSuccessfulLogin(response) {
    const decodedToken = jwtDecode(response.data.access_token);
    const userInfo = {
      nombre: capitalizeUsername(decodedToken.user_data.nombre),
      otp_activo: decodedToken.user_data.otp_activo,
    };
    setUserInformation(userInfo);
    localStorage.setItem('userInformation', JSON.stringify(userInfo));
    setAuthTokens({ accessToken: response.data.access_token, refreshToken: response.data.refresh });
    setSuperuser(decodedToken.super);
    setEmpresas(decodedToken.user_data.empresas);
    setLoginError(null);
    handleEmpresaSeleccionada(decodedToken.user_data.empresas);
    handlePermisos(response.data.access_token, decodedToken.user_data.empresas);
  }

  function handleLoginError(err) {
    setLoginError('Error procesando el login');
  }

  const logout = () => {
    localStorage.removeItem('authTokens');
    localStorage.removeItem('userInformation');
    localStorage.removeItem('empresaSeleccionada');
    localStorage.removeItem('empresasDisponibles')
    setAuthTokens({ accessToken: null, refreshToken: null });
    setView('login');
    setLoginError(null);
  };

  const refresh = async () => {
    try {
      const newTokens = await refreshTokens(authTokens.refreshToken);
      setAuthTokens({ accessToken: newTokens.access, refreshToken: newTokens.refresh });
      setLoginError(null);
    } catch (error) {
      setLoginError('No se pudo refrescar el token');
    }
  };

  const value = {
    authTokens,
    login,
    showVerification,
    logout,
    refresh,
    superuser,
    permisos,
    userInformation,
    empresasDisponibles,
    empresaSeleccionada,
    seleccionar_empresa,
    loginError,
    actualView,
    setView,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
